@import "../../_cf-variables";

$scrollbarOverflow: 40px;

.cf-chat {
	display: block;
	width: 100%;
	height: auto;
	min-height: 100%; // to play nice with flexbox;
	overflow: hidden;
	// flow children to bottom
	display: flex;
	flex-flow: column nowrap;
	justify-content: flex-end;
	align-items: baseline;

	margin-bottom: -10px;

	.scrollable {
		// display: block;
		width: calc(100% + #{$scrollbarOverflow});
		padding-right: #{$scrollbarOverflow};
		// height: auto;
		max-height: 100%;
		min-height: 40px;
		// overflow-y: auto;
		flex-grow: 1;
		// overflow:scroll;
		display: flex;
		flex-direction: column-reverse;
		/* 'column' for start, 'column-reverse' for end */
		overflow-y: scroll;

		@supports (-ms-ime-align: auto) {
			overflow: hidden;
		}
	}

	.scrollableInner {
		// height: 100%;
		position: relative;
		display: flex;
		flex: 0 0 auto;
		flex-direction: column;
		padding-bottom: 10px; // allow for bounce;
		padding-top: 70px;

		overflow: hidden;

		&.scroll {
			flex: 1 1 auto;
			height: 100%;
			overflow-y: scroll;
			margin-bottom: 20px;

			@supports (-ms-ime-align: auto) {
				margin-right: -16px; // hide scrollbar
			}
		}
	}
}