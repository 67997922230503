/*
    Globals
*/
$cf-base-font-size: 18px !default; // Default is 12px. Positions and sizes will be relative to this size.
$cf-mobile-font-size: 16px !default; // Default is 12px. Positions and sizes will be relative to this size.

$cf-color-background: #fff !default;
$cf-color-primary: #ff6100 !default;
$cf-color-secondary: #f3f3fe !default;
$cf-color-error: #fe8d84 !default;

$cf-font-family: 'Helvetica Neue',
'Neue Helvetica W01',
Helvetica,
Arial,
-apple-system,
BlinkMacSystemFont,
'Segoe UI',
Roboto,
'Apple Color Emoji',
'Segoe UI Emoji',
'Segoe UI Symbol',
sans-serif !default;

$cf-breakpoint-medium: relativeValue(768) !default;

$cf-anim-time: 0.75s !default;
$cf-ease-easeIn: cubic-bezier(0.550, 0.055, 0.675, 0.190) !default;
$cf-ease-easeOut: cubic-bezier(0.215, 0.610, 0.355, 1.000) !default;
$cf-ease-easeInOut: cubic-bezier(0.645, 0.045, 0.355, 1.000) !default;
$cf-ease-easeOutBack: cubic-bezier(0.175, 0.885, 0.320, 1.275) !default;

/*
    Response
*/
$cf-chat-response-font-size: $cf-base-font-size !default;
$cf-chat-response-border-radius: relativeValue(16px) !default;

$cf-color-chat-response-default-text: #4d4d8b !default;
$cf-color-chat-response-default-bg: $cf-color-secondary !default;

$cf-color-chat-response-user-text: lighten($cf-color-primary, 10%) !default;
$cf-color-chat-response-user-bg: $cf-color-primary !default;

$cf-avatar-area: relativeValue(33px) !default; //The width/height of Robot and Human image/icon.
$cf-avatar-size: 100% !default;
$cf-avatar-background: $cf-color-secondary !default;

/*
    Input
*/
$cf-color-input-text: #363c4e !default;
$cf-input-field-height: relativeValue(40px) !default;
$cf-input-field-border-radius: relativeValue(0) !default;
$cf-input-submit-background: none !default;
$cf-input-icon-color: $cf-color-secondary !default;
$cf-input-font-size: $cf-base-font-size !default;
$cf-input-font-size-mobile: $cf-mobile-font-size !default;

$cf-input-color-error-text: $cf-color-error !default;
$cf-input-color-error-bg: lighten($cf-color-error, 20%) !default;