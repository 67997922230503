.page-error {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-color: #f5f5f5;

  .error-message {
    color: #333;

    .icon {
      text-align: center;

      svg {
        width: 6rem;
        height: 6rem;
        color: #e53935;
      }
    }

    h1 {
      font-size: 2rem;
      font-weight: bold;
      color: #333;
      margin-bottom: 1rem;
    }

    p {
      font-size: 1.2rem;
      color: #333;
      margin-bottom: 1rem;
    }
  }
}