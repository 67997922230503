@import "./cf-variables";

// boilerplate
.conversational-form {
	text-rendering: optimizeLegibility;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;

	// if you want to hide the Conversational Form, use display none.
	visibility: visible !important;

	*,
	*:before,
	*:after {
		box-sizing: border-box;
		outline: none;
	}

	&:before {
		content: "";
		width: 100%;
		height: 50px;
		pointer-events: none;
		//	background: linear-gradient(to bottom, rgba($cf-color-background, 1) 0%, rgba($cf-color-background, 0) 100%);
		position: absolute;
		top: 0px;
		left: 0px;
		z-index: 2;
	}

	button {
		border: none;
		background: none;
		margin: 0px;
		padding: 0px;
		outline: none;
		-webkit-tap-highlight-color: rgba(0, 0, 0, 0) !important;
	}

	menu {
		padding: 0px;
		margin: 0px;
	}

	ul,
	ol,
	li {
		margin: 0;
		padding: 0;
	}

	a,
	a:active,
	a:visited {
		color: currentColor;
	}

	button,
	a {
		cursor: pointer;
	}

	font-family: $cf-font-family;
	font-size: $cf-chat-response-font-size;
	line-height: $cf-chat-response-font-size+4;

	@media (max-width: $cf-breakpoint-medium) {
		font-size: $cf-mobile-font-size;
		line-height: $cf-mobile-font-size + 4;
	}

	position: absolute;
	top: 0px;
	left: 0px;
	right: 0px;
	bottom: 0px;
	z-index: 999;
	margin: auto;
	background: $cf-color-background;
	overflow: hidden;

	opacity: 0;
	will-change: opacity;

	.conversational-form-inner {
		padding: 0px 20px;
		position: absolute;
		width: 100%;
		min-height: 100%;
		max-height: 100%;
		bottom: 0px;
		height: auto;
		overflow: hidden;
		margin-bottom: auto;
		// flow children to bottom
		display: flex;
		flex-flow: column nowrap;
		justify-content: flex-end;
		align-items: baseline;

		@media (max-width: $cf-breakpoint-medium) {
			padding: 0px 14px;
		}
	}

	.conversational-form--enable-animation & {
		transition: opacity $cf-anim-time * 0.25 $cf-ease-easeOut;
	}

	// test

	// disable user selection for the app feel
	* {
		// user-select none
		-webkit-tap-highlight-color: rgba(255, 255, 255, 0);
		tap-highlight-color: rgba(255, 255, 255, 0);
	}
}

.conversational-form--show {
	opacity: 1;
}



.dot-loader {
	display: flex;
	justify-content: center;
	align-items: center;

	>div {
		width: 16px;
		height: 16px;
		border-radius: 100%;
		margin: 5px;
		background-image: linear-gradient(145deg, rgba(255, 255, 255, 0.5) 0%, rgba(0, 0, 0, 0) 100%);
		animation: bounce 0.4s linear infinite;
	}
}




.blue {
	background-color: #0099ff;
}

.orange {
	background-color: #ff6100;
	animation-delay: 0.1s;
}

.yellow {
	background-color: #ffb629;
	animation-delay: 0.2s;
}

@keyframes bounce {

	0%,
	50%,
	100% {
		transform: scale(1);
		filter: blur(0px);
	}

	25% {
		transform: scale(0.6);
		filter: blur(3px);
	}

	75% {
		filter: blur(3px);
		transform: scale(1.4);
	}
}

.input-back-btn {
	display: block;
	cursor: pointer;
	width: 36px;
    height: 36px;
	background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='128' height='128' fill='%23ff6100' class='bi bi-arrow-90deg-left' viewBox='0 0 16 16'%3E%3Cpath fill-rule='evenodd' d='M12.5 15a.5.5 0 0 1-.5-.5v-13a.5.5 0 0 1 1 0v13a.5.5 0 0 1-.5.5zM10 8a.5.5 0 0 1-.5.5H3.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L3.707 7.5H9.5a.5.5 0 0 1 .5.5z'/%3E%3C/svg%3E");
	background-size: cover;
	margin: 5px 16px;
}