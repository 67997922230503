@import "../../_cf-variables";

.conversational-form.done {
	.cf-chat-response {
		pointer-events: none;
	}
}


.cf-chat-response {
	max-width: 90%;

	@media (min-width: $cf-breakpoint-medium) {
		max-width: 70%;
	}

	min-width: 120px;
	position: relative;
	backface-visibility: hidden;
	padding-left: $cf-avatar-area+12px;
	margin-top: 8px;
	color: $cf-color-chat-response-default-text;
	margin-left: 0px;
	margin-right: auto;
	word-break: break-all;
	display:flex;
	flex-shrink:0;
	flex-grow:1;
	position: absolute;
	visibility: hidden;
	will-change:height;
	transition:height $cf-anim-time * 0.45 $cf-ease-easeOut;
	padding-bottom: 12px;

	@media (max-width: $cf-breakpoint-medium) {
		padding-left: ($cf-avatar-area / 1.4) + 12px;
	}

	&.show {
		position: relative;
		visibility: visible;
	}

	&.can-edit {
		cursor: pointer;

		&:hover {
			// color: darken($cf-color-chat-response-default-text, 75%);

			.text>p {
				background: darken($cf-color-chat-response-user-bg, 8%);
			}
		}
	}

	&.user {
		justify-content: flex-end;
		margin-right: 0px;
		margin-left: auto;
		padding-left: 0;
		padding-right: calc(#{$cf-avatar-area} + 14px);
		color: lighten($cf-color-secondary, 90%);

		@media (max-width: $cf-breakpoint-medium) {
			padding-right: ($cf-avatar-area / 1.4) + 12px;
		}

		.thumb {
			left: auto;
			right: 0px;
		}

		.text {

			>p {
				background: $cf-color-chat-response-user-bg;
				border-radius: $cf-chat-response-border-radius 0px $cf-chat-response-border-radius $cf-chat-response-border-radius;
				margin-right: 0px;
				margin-left: auto;
			}
			.editable:hover {
					background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='%23fff' class='bi bi-pencil-fill' viewBox='0 0 16 16'%3E%3Cpath d='M12.854.146a.5.5 0 0 0-.707 0L10.5 1.793 14.207 5.5l1.647-1.646a.5.5 0 0 0 0-.708l-3-3zm.646 6.061L9.793 2.5 3.293 9H3.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.207l6.5-6.5zm-7.468 7.468A.5.5 0 0 1 6 13.5V13h-.5a.5.5 0 0 1-.5-.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.5-.5V10h-.5a.499.499 0 0 1-.175-.032l-.179.178a.5.5 0 0 0-.11.168l-2 5a.5.5 0 0 0 .65.65l5-2a.5.5 0 0 0 .168-.11l.178-.178z'/%3E%3C/svg%3E");
					background-repeat: no-repeat;
					background-position: calc(100% - 2px) calc(100% - 25px);
					background-size: 10px;

			}
		}

		&.peak-thumb {
			.thumb {
				transform: scale(1, 1) translateY(0px);
				opacity: 1;

				.conversational-form--enable-animation & {
					transition-delay: 0.2s, 0.2s, 0.2s;
				}
			}
		}
	}

	&.robot {
		&.peak-thumb {
			.thumb {
				transform: scale(1, 1) translateY(0px);
				opacity: 1;

				.conversational-form--enable-animation & {
					transition-delay: 0.2s, 0.2s, 0.2s;
				}
			}
		}
	}

	&.disabled {
		pointer-events: none;
		color: lighten($cf-color-chat-response-default-text, 25%);

		.text {
			background: lighten($cf-color-chat-response-user-bg, 50%);
		}
	}

	&.thinking {
		.text .thinking {
			display: block;
		}
	}

	.text {
		display: flex;
		flex-flow: column;
		align-items: start;
		position: relative;
		min-height: 40px;
		height: auto;
		white-space: pre-wrap;
		word-break: normal;
		user-select: none;

		>div {
			display: inline-block;
		}

		strong {
			font-weight: 900;
		}

		>p {
			display: inline-block; // wrap container around text;
			margin: 0px;
			padding: 0px;
			background: $cf-color-chat-response-default-bg;
			padding: relativeValue(9px) relativeValue(14px) relativeValue(9px) relativeValue(14px);

			@media (max-width: $cf-breakpoint-medium) {
				padding: relativeValue(6px) relativeValue(11px) relativeValue(7px) relativeValue(11px);
			}

			.conversational-form--enable-animation & {
				will-change: transform, opacity;
				transform: scale(0, 0) translateY(30px);
				opacity: 0;
			}

			&.show {
				.conversational-form--enable-animation & {
					animation: moveIn $cf-anim-time * 0.35 forwards $cf-anim-time * 0.5 $cf-ease-easeOut;
				}
			}

			// User response containing an image
			&.hasImage {
				padding: 0;
				overflow: hidden;
				background: none; // To avoid background color being vaguely visible behind image

				img {
					margin: 0;
					padding: 0;
				}

				>span {
					display: block;
					padding: 5px 6px 6px 18px;
					background: $cf-color-chat-response-user-bg;
				}
			}

			// border radius rules
			border-radius: 0px $cf-chat-response-border-radius $cf-chat-response-border-radius $cf-chat-response-border-radius;
			transition: background $cf-anim-time * 0.5 $cf-ease-easeOut;

			&:not(:only-child) {
				border-radius: 0px $cf-chat-response-border-radius $cf-chat-response-border-radius 0px;

				&:first-child {
					border-radius: $cf-chat-response-border-radius $cf-chat-response-border-radius $cf-chat-response-border-radius 0px;
				}

				&:last-child {
					border-radius: 0px $cf-chat-response-border-radius $cf-chat-response-border-radius $cf-chat-response-border-radius;
				}

				&:not(:first-child) {
					margin-top: relativeValue(4px);
				}
			}

			//--------------------

			>img {
				max-width: 140px;
				padding-bottom: relativeValue(5px);
				width: 100%;
				height: auto;
				display: block;
				margin-bottom: relativeValue(4px);
			}
		}

		&:after {
			content: "";
			display: block;
			position: absolute;
			top: 50%;
			left: relativeValue(20px);
			transform: translateY(-50%);
			padding-right: relativeValue(20px);
		}

		.thinking {
			position: relative;
			display: table;
			user-select: none;
			display: none;
			transform-origin: 0% 50%;
			transform: scale(1.5);

			@for $num from 1 through 3 {
				span:nth-child(#{$num}) {
					animation: dot-fade 1s linear ($num * (1 / 3)+s) infinite;
					animation-delay: ($num * (1 - (1 / 3))+s);
				}
			}
		}

		svg.cf-icon-file {
			width: relativeValue(10px);
			height: auto;
			display: inline-block;
			margin-right: relativeValue(7px);

			g {
				fill: $cf-color-secondary;
			}
		}
	}

	.thumb {
		position: absolute;
		top: 0px;
		left: 0px;
		display: block;
		width: $cf-avatar-area;
		height: $cf-avatar-area;
		border-radius: 50%;
		background: $cf-avatar-background;
		background-size: cover;
		// background-size: $cf-avatar-size;
		background-repeat: no-repeat;
		background-position: 50% 50%;
		opacity: 0;
		overflow: hidden;
		transform-origin: 50% 50%;
		transform: scale(0.001, 0.001) translateY(50px);
		will-change: transform, opacity;

		@media (max-width: $cf-breakpoint-medium) {
			width: $cf-avatar-area / 1.26;
			height: $cf-avatar-area / 1.26;
		}

		.conversational-form--enable-animation & {
			transition: transform $cf-anim-time * 0.35 $cf-ease-easeOut, opacity $cf-anim-time * 0.5 $cf-ease-easeOut;
		}

		// Centered text, e.g. initials.
		>span {
			display: block;
			line-height: relativeValue(24px);
			text-align: center;
			font-weight: bold;
			font-size: $cf-base-font-size * .8;

			&[length="1"] {
				font-size: $cf-base-font-size * 1.6;
			}

			&[length="3"] {
				font-size: $cf-base-font-size * .66;
			}
		}
	}
}

.edit-icon {
	position: absolute;
	top: 0;
	right: 0;
	width: relativeValue(20px);
	height: relativeValue(20px);
	cursor: pointer;
	opacity: 0;
	transition: opacity $cf-anim-time * 0.5 $cf-ease-easeOut;
	will-change: opacity;

	&:hover {
		opacity: 1;
	}

	svg {
		width: relativeValue(20px);
		height: relativeValue(20px);
	}

	g {
		fill: $cf-color-secondary;
	}
}

@keyframes dot-fade {
	0% {
		opacity: 1;
	}

	15.1111% {
		opacity: 0;
	}

	33.333% {
		opacity: 1;
	}

	100% {
		opacity: 1;
	}
}

@keyframes bounce {
	0% {
		transform: scale(1, 1);
		opacity: 1;
	}

	50% {
		transform: scale(1.05, 1.05);
		opacity: 0.85;
	}

	100% {
		transform: scale(1, 1);
		opacity: 1;
	}
}

@keyframes moveIn {
	0% {
		transform: scale(0001, 0001) translateY(60px);
		opacity: 0;
	}

	100% {
		transform: scale(1, 1) translateY(0px);
		opacity: 1;
	}
}